<template>
    <v-col>
    <v-dialog
        transition="scale-transition"
        max-width="600"
        v-model="show"
    >
    <v-card>
    
    <v-toolbar flat>
        <v-btn @click='show = false' icon large>
          <v-icon color="primary" dense large>
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title :style="{'color': `${$vuetify.theme.dark ? '#E7E3FCAD' : '#111B21'} `}" >
            Add Domain
        </v-toolbar-title>
        <v-spacer />
    </v-toolbar>

    <v-divider></v-divider>
    <v-row no-gutters>
        <v-col 
        cols="7" md="9" lg="9" sm="9" xs="10">
            <v-text-field
                class="ml-5 mt-6"
                label="e.g. mystunningwebsite.com"
                outlined
                dense
            ></v-text-field>
        </v-col>
        <v-col cols="4" lg="3" sm="3" md="3">
            <v-btn class="mt-6 ml-4" large color="primary">
                Check
            </v-btn>
        </v-col>
    </v-row>
    </v-card>
    </v-dialog>
    </v-col>
</template>

<script>
import { mdiClose, mdiChevronLeft } from '@mdi/js'

export default {
  name: 'AddDomainDialog',
  setup() {
    return {
      icons: {
        mdiClose,
        mdiChevronLeft,
      },
    }
  },
  props: {
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      isDialogVisible: false,
      selectItems: ['WhatsApp', 'Facebook', 'Instagram'],
      selectedItem: '',
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style></style>
